import React, { useEffect } from 'react'
import api from '../services/api'
import { useDispatch } from 'react-redux'
import { updateCoffees } from '../state/reducers/coffees'
import { updateUser } from '../state/reducers/user'
import { showLoading, hideLoading } from '../state/reducers/loading'

import Layout from '../components/layout'
import Container from '../components/container'
import Title from '../components/title'
import Videos from '../components/videos'
import Packages from '../components/packages'
import HowTo from '../components/howTo'
import Perks from '../components/perks'
import Extras from '../components/extras'
import Cta from '../components/cta'

const Delivery = ({ params }) => {
  const dispatch = useDispatch()

  const requestCoffees = () => {
    dispatch(showLoading('Carregando...'))

    api.get(`/deliveries/${params.id}`).then((response) => {
      if(response.data.coffee_beans){
        dispatch(updateCoffees(response.data.coffee_beans))
      }
      if(response.data.user){
        dispatch(updateUser(response.data.user))
      }

      dispatch(hideLoading())
    }).catch(() => {
      dispatch(hideLoading())
    })
  }

  useEffect(() => {
    requestCoffees()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Layout>
      <Container>
        <Title />
        <Videos />
        <Packages />
        <HowTo />
        <Perks />
        <Extras />
        <Cta />
      </Container>
    </Layout>
  )
}

export const Head = () => {
  return(
    <title>
      Preparamos a sua caixinha com muito carinho
    </title>
  )
}

export default Delivery

import React from 'react'
import { useSelector } from 'react-redux'
import { StyledTitle } from './style'

const Title = () => {
  const user = useSelector((state) => state.user)

  if(user && user.first_name){
    return(
      <StyledTitle>
        {user.first_name}, preparamos a sua <strong>caixinha</strong> com muito carinho
      </StyledTitle>
    )
  }
  else {
    return(
      <StyledTitle>
        Preparamos a sua <strong>caixinha</strong> com muito carinho
      </StyledTitle>
    )
  }
}

export default Title

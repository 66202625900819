import styled from 'styled-components'

export const StyledCta = styled.div`
  margin-top: 100px;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.large};

  div {
    line-height: 1;

    & + div {
      margin-top: 50px;

      &:before {
        content: '';
        width: 50px;
        height: 2px;
        display: block;
        background: #ddd;
        margin: 0 auto 50px;
      }
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    
  }
`
import React from 'react'
import { StyledCta } from './style'

const Cta = () => {
  return(
    <StyledCta>
      <div>
        Para dúvidas ou sugestões, <a href="https://coffeeandjoy.com.br/contact">entre em contato</a> com a gente.
      </div>

      <div>
        Boa jornada e bons cafés.
      </div>
    </StyledCta>
  )
}

export default Cta
